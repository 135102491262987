import React, {useEffect, useState} from 'react';
import './Liste.css';
import ApiService from "./services/ApiService";

function Listen({request, updateList}) {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        setLoading(true);
        ApiService.fetchListen(request)
            .then(data => {
                setList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Liste:', error));
    }, [updateList]);

    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <div className="border-liste">
            {request === 'verein' ? (
                <ul className="liste">
                    {list.map((current, index) => (
                        <li key={current.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                            <div className="grid-2">
                                <label> {current.name} </label>
                                <label> {current.verband} </label>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                request === 'mannschaft' ? (
                    <ul className="liste">
                        {list.map((current, index) => (
                            <li key={current.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                <div className="grid-3">
                                    <label> {current.name} </label>
                                    <label> {current.verein.name} </label>
                                    <label> {current.disziplin.code} </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (

                    <ul className="liste">
                        {list.map((current, index) => (
                            <li key={current.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                <div className="grid-person">
                                    <label className="list-row">{current.vorname}</label>
                                    <label className="list-row">{current.nachname}</label>
                                    <label className="list-row">{current.verein.name}</label>
                                    <label className="list-row">{current.geburtsjahr}</label>
                                    <label className="list-row">{current.geschlecht}</label>
                                </div>
                            </li>
                        ))}
                    </ul>

                )
            )
            }
        </div>
    )

}

export default Listen;
