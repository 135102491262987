import React, {useEffect, useState} from 'react';
import '../Setzliste.css';
import NavbarLiga from "../components/NavbarLiga";
import ApiService from "../services/ApiService";
import Setzliste from "../Setzliste";

function Ab10mkSetzliste() {
    const [loading, setLoading] = useState(false);
    const [ligaList, setLigaList] = useState([]);

    const [mannschaftenList, setMannschaftenList] = useState(null);
    const [setzlisteData, setSetzlisteData] = useState({});

    const handleLigaClick = (selectedLiga) => {
        setMannschaftenList(null);

        ApiService.fetchMannschaftenByLiga(2024, selectedLiga.id)
            .then(data => {
                setMannschaftenList(data);
                return Promise.all(data.map(mannschaft => {
                    return ApiService.fetchSetzliste(2024, mannschaft.id)
                        .then(setzliste => ({mannschaftId: mannschaft.id, setzliste}))
                }));
            })
            .then(results => {
                const newSetzlisteData = {};
                results.forEach(({mannschaftId, setzliste}) => {
                    newSetzlisteData[mannschaftId] = setzliste;
                });
                setSetzlisteData(newSetzlisteData);
            })
            .catch(error => console.error('Fehler beim Laden der Daten für die rechte Spalte:', error));
    };

    useEffect(() => {
        setLoading(true);
        ApiService.fetchLigenByDisziplin("AB10mk")
            .then(data => {
                setLigaList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="element-content element-background">
            <div className="grid-show-liste">
                <div className="top-panel-liste">
                    <NavbarLiga ligaList={ligaList} handleLigaClick={handleLigaClick}></NavbarLiga>
                </div>
                <div className="body-panel-liste">
                    {mannschaftenList && (
                        mannschaftenList.map((current) => (
                            setzlisteData[current.id] && setzlisteData[current.id].length > 0 && (
                                <div key={current.id} className="setzliste-block">
                                    <label> {current.name} </label>
                                    <Setzliste mannschaft={current}
                                               setzliste={setzlisteData[current.id]}/>
                                </div>
                            )
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default Ab10mkSetzliste;